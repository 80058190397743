/**
 * Copyright (C) 2022 Léo Gorman
 * 
 * This file is part of rhomis-data-app.
 * 
 * rhomis-data-app is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * rhomis-data-app is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 * 
 * You should have received a copy of the GNU General Public License
 * along with rhomis-data-app.  If not, see <http://www.gnu.org/licenses/>.
 */

 .form-button{
     width:100%;
     height:30px;
     text-align: left !important;
    padding-left:16px;
    justify-content: left;
      border: 0;
      background-color: #343A40 !important;
      color: white !important
 }

 .form-button:hover{
     background-color: rgb(82,87,92) !important;
 }