


.form-group-spaced {
    margin-top: 1em;
    margin-bottom: 1em;
}

.registration-card{
    justify-self: center;
    width: 100%
}
.no-mobile-card{
    justify-self: center;
    width: 50%;
}

.child-no-mobile-container{
    display: grid;
    align-items:center;    
    min-width: 500px;
    width:50%;
    height: 100%;
}

.card-style{
    display: flex;
    flex-direction: column;
    /* min-height: 600px; */

}

.icon-container{
    margin-top: 1em;
    margin-bottom: 1em;
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.button-container{
    margin-top: 2em;
    margin-bottom: 2em;
    width:100%;
    display: grid;
    place-items: center;
    
    

}

.login-buttons{
margin-left: 10px;
width: 10em;
    /* margin-right: 10px; */

}

