/**
 * Copyright (C) 2022 Léo Gorman
 * 
 * This file is part of rhomis-data-app.
 * 
 * rhomis-data-app is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * rhomis-data-app is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 * 
 * You should have received a copy of the GNU General Public License
 * along with rhomis-data-app.  If not, see <http://www.gnu.org/licenses/>.
 */


.filter-button, .end-button{
    margin-right: 5px;
    margin-bottom: 5px;
}


.end-button-container{
    width: 80vw;
    display: grid;
    place-items: end;
}

.card-content{
    margin: 20px;
}


.table > tbody > tr > td {
    vertical-align: center;
}

.sub-page-container {
    padding-top: 20px;
    margin: 30px;
    width:80vw;
}

.button-row{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

}

.project-management-card{
    width:80%;
    margin: 20px;
}


.qr-code-container{
    margin: 20px;
    width: 100%;
    display: flex;
    /* background-color: blue; */
    justify-content: center;
}










