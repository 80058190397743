/**
 * Copyright (C) 2022 Léo Gorman
 * 
 * This file is part of rhomis-data-app.
 * 
 * rhomis-data-app is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * rhomis-data-app is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 * 
 * You should have received a copy of the GNU General Public License
 * along with rhomis-data-app.  If not, see <http://www.gnu.org/licenses/>.
 */

.main-app-background {
  background-color: #1B8697;
  overflow-x:hidden; 
  /* overflow-y:hidden;  */

}

.main-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

/* tr, td {
  height: 10px !important;
  max-height: 10px !important;
} */




.main-card{
  margin-top: 50px;
  margin-bottom: 25px;
  min-height: 20em;

}

.main-card-header-container{
  display: flex;
  align-items: center; 
  vertical-align: center;
}

.main-card-header{
  align-self: center;
  margin-top: 7px;
  margin-left: 8px;

}

.main-card-header-item{
  margin: 5px;  
  border-color:white;
  border-style: solid;
  border-radius: 5px;
  border-width: 1px;
  padding-left:10px;
  padding-right:10px;
  padding-bottom:5px;
  padding-top:5px;
  
  }



.sub-card{
  /* justify-items: center; */
  display: grid;
  vertical-align: top;
  align-items: center;
  
  margin: 15px;
  padding-left: 20px;
  padding-right: 20px;
  background-color:#212529!important;
  transition: 0.3s;
}

.sub-card-text{
  margin-top: 20px;
  text-align: center;
}

.portal-icon{
  padding-top: 20px;
}

.portal-card{
  width: 250px;
  height:250px;
}

.project-card{
  width: 200px;
  height:200px;
}



.portal-card:hover {
  background-color:#001f3f!important;   
}

.project-card:hover{
  background-color:#001f3f !important;   
}

.sub-page-container {
  padding-top: 20px;
  width:60vw;
}

.portal-card-header-container{
  display:grid;
  place-content: center;
  text-align: center;

}
.portal-card-header {
  margin-top: 10px;
  color: white;


}
.main-card-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* margin: 3em; */
}







