.side-bar-container{ 
    color: "white";
    background-color: "blue";
    width: "100%";
    justify-items: "right" 
}

.side-bar-item{
    border: 0;
    color:white;
    background-color:#212529;
    font-size: 22px;
    width:100%;
    transition: .3s;
}

.side-bar-item:hover,
.form-link:hover{
    background-color:white;
    font-size: 27px;
    transition: .3s;
}

.form-link{
    
    padding-left: 1.7em;
    width: 100%;
    text-align: left;
    background-color: #212529;
    color: white;
    border: 0;
}

.side-bar-link,
.logout-button{
    border: 0;
    margin-left: 1em;
    color:white;
}


.logout-button{
    border: solid;
    border-radius: 10px;
    padding:10px;
    margin-right:10px !important;


    border-width: 1px;
    transition: .3s;
}

.side-bar-link:hover,
.form-link:hover,
.logout-button:hover{
    color:black;
}

.logout-button:hover{
    background-color: white;
}

.menu-button{
    display: grid;
    place-content: center;
    margin-left: 10px !important;
    height:40px;
    width:40px;

    padding:10px;
    border: solid;
    border-radius: 10px;
    border-width: 1px;
    transition: .3s;
    border-color: whitesmoke;
background-color: #212529 !important;
}

.menu-button-icon{
    color:white

}

.menu-button-icon:hover{
    color:black
}

.menu-button:hover{
    
    border-color: #212529;
background-color: white !important;
}
