/**
 * Copyright (C) 2022 Léo Gorman
 * 
 * This file is part of rhomis-data-app.
 * 
 * rhomis-data-app is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 * 
 * rhomis-data-app is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 * 
 * You should have received a copy of the GNU General Public License
 * along with rhomis-data-app.  If not, see <http://www.gnu.org/licenses/>.
 */


.waring{
    color: red;
}

.form-group-spaced {
    margin-top: 1em;
    margin-bottom: 1em;
}

.registration-card{
    justify-self: center;
    width: 100%
}
.login-card{
    justify-self: center;
    width: 50%;
}

.child-login-container{
    display: grid;
    align-items:center;    
    min-width: 200px;
    width:50%;
    height: 100%;
}

.card-style{
    display: flex;
    flex-direction: column;
    /* min-height: 600px; */

}

.icon-container{
    margin-top: 1em;
    margin-bottom: 1em;
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.button-container{
    margin-top: 2em;
    margin-bottom: 2em;
    width:100%;
    display: grid;
    place-items: center;
    
    

}

.login-buttons{
margin-left: 10px;
width: 10em;
    /* margin-right: 10px; */

}

