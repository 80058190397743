




.units-table{
    table-layout: fixed !important;

}

.table-div{
    width: 100%; 
    max-height: 300px; 
    overflow-y: scroll;
    border-radius: 3px;
    border: 0.5px;
    border-color: lightgrey;
    border-style: solid;

}

.data-table{
    
}



